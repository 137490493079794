import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'
import Layout from '../../components/layout'
import Container from '../../components/Container'
import s from './WhatWePlay.scss'
import { markdownParser } from '../../utils'
import header from '../../../content/what_we_play/header.json'
import howWePlay from '../../../content/what_we_play/howWePlay.json'
import service from '../../../content/what_we_play/service.json'
import announcementContent from '../../../content/what_we_play/announcement.json'
import Announcement from '../../components/Announcement/Announcement'
import KeyPoints from '../../components/KeyPoints'

const headerContent = header.content.find(data => data.display)
const howWePlayContent = howWePlay.areas.filter(data => data.display)
const serviceContent = service.services.filter(data => data.display)

const { announcement } = announcementContent

const WhatWePlay = ({ location }) => (
  <Layout location={location}>
    <div className="WhatWePlay">
      <div className="banner bgMidnight" />
      <section className="bgPrimaryLight top">
        <Container flexDirection="flexColumn" justifyCenter>
          <div className="content">
            <h1 className="title">
              <b>{headerContent?.title}</b>
              {headerContent?.subtitle}
            </h1>
            <p>{markdownParser(headerContent?.body)}</p>
          </div>
        </Container>
        <img
          src="/decorations/monitor-hero.svg"
          alt=""
          className="illustration"
        />
        <img
          src="/decorations/monitor-hero-background.svg"
          alt=""
          className="illustration-background"
        />
        <div className="border" />
      </section>
      <KeyPoints
        title={howWePlay?.title}
        subtitle={howWePlay?.subtitle}
        points={howWePlayContent}
      />
      <section className="services">
        <h2 className="thin sectionTitle">
          WHERE WE <b>SHINE</b>
        </h2>
        {serviceContent.map(({ title, body, techs, image }, index) => {
          const odd = index % 2 !== 0
          const cx = classNames('service', odd && 'darkBackground')
          return (
            <section className={cx} key={`service_${Math.random()}`}>
              <Container
                flexDirection={!odd ? 'flexRowReverse' : ''}
                justifySpaceBetween
              >
                <div style={{ width: '130x' }} />
                <div className="content">
                  <h3 className="title">
                    <b>{title}</b>
                  </h3>
                  <p className="serviceBody">{markdownParser(body)}</p>
                  <div className="box">
                    {techs.map(tech => (
                      <div
                        key={`tech_${Math.random()}`}
                        style={{ backgroundColor: `${tech.color}` }}
                      >
                        <p>{tech.title}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <img
                  src={image}
                  width="250px"
                  alt=""
                  className={`${!odd ? 'leftAligned' : 'rightAligned'}`}
                />
              </Container>
            </section>
          )
        })}
      </section>

      <Announcement
        design="bgPrimaryLight"
        title={announcement.title}
        subtitle={announcement.subtitle}
        button={{
          link: announcement.buttonLink,
          text: announcement.buttonText,
          design: 'inverse',
        }}
      />
      <div className="spikesBorder" />
    </div>
    <link
      rel="stylesheet"
      href="//use.fontawesome.com/releases/v5.0.7/css/all.css"
    />
  </Layout>
)

WhatWePlay.propTypes = {
  location: PropTypes.shape().isRequired,
}

export default withStyles(s)(WhatWePlay)
